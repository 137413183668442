export default function RateChange({ change, years }) {
    const roundedPercentage = (value) => {
        if (value === 0) return "0.00%";
        if (!value) return "";
        if (Math.abs(value) >= 100) return value.toFixed() + "%";
        return value.toFixed(2) + "%";
    };

    const getStyle = (value) => {
        if (value === 0) return null;
        if (value > 0) return { color: "MediumSeaGreen" };
        else if (value < 0) return { color: "Tomato" };
    };

    let title = null;
    if (years) {
        const changePerYear = Math.pow(1 + change / 100, 1 / years) - 1;
        const percentChangePerYear = changePerYear * 100;
        title = `${percentChangePerYear.toFixed(2)}% annualised`;
    }

    return (
        <td style={getStyle(change)} title={title}>{roundedPercentage(change)}</td>
    );
}
